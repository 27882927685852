import { useState, useEffect } from 'react';
import axios from '../utils/axios';

export default function useGetSearchFunction(
    query,
    lang,
    fullSearch = false,
    noOnMount = false
) {
    const [searchResults, setSearchResults] = useState([]);

    useEffect(() => {
        let cancel;

        if (noOnMount) {
            if (query.length > 0) {
                axios({
                    method: 'POST',
                    url: `${process.env.BACKEND_URL}/api/v1/service/search`,
                    data: {
                        lang,
                        value: query,
                        fullSearch,
                    },
                    cancelToken: new axios.CancelToken((c) => (cancel = c)),
                })
                    .then((res) => {
                        setSearchResults(res.data);
                    })
                    .catch((e) => {
                        if (axios.isCancel(e)) return;
                    });

                return () => cancel();
            }
        } else {
            axios({
                method: 'POST',
                url: `${process.env.BACKEND_URL}/api/v1/service/search`,
                data: {
                    lang,
                    value: query,
                    fullSearch,
                },
                cancelToken: new axios.CancelToken((c) => (cancel = c)),
            })
                .then((res) => {
                    setSearchResults(res.data);
                })
                .catch((e) => {
                    if (axios.isCancel(e)) return;
                });

            return () => cancel();
        }
    }, [query, lang]);

    return { searchResults };
}
