import styled from 'styled-components';

export const HeaderCss = styled.header`
  background-color: white;
  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60vh;
  }
  .header-form {
    width: 100%;
    font-weight: 600;
    h1 {
      margin: 0;
      font-size: 3.4rem;
      line-height: 4rem;
      margin-bottom: 4rem;
      font-weight: 700;
      width: 95%;
      padding: 0 1.6rem;
      span {
        color: var(--color-french-violet);
      }
    }
    form {
      display: flex;
      padding: 0 1.6rem;
      input {
        font-size: 1.5rem;
        outline: none;
      }
      .input-and-result {
        width: 100%;
        position: relative;
        .search-results {
          position: absolute;
          top: 120%;
          width: 100%;
          list-style: none;
          margin: 0;
          padding: 0;
          z-index: 3;
          background: white;
          box-shadow: 0px 63px 50px rgba(67, 67, 68, 0.0705882);
          border-radius: 4px;
          a {
            padding: 1.6rem;
            display: inline-block;
            font-size: 1.5rem;
            font-weight: 600;
            color: #565459;
            min-width: 100%;
            &:hover {
              color: ${(props) => props.theme.colors.frenchViolet};
              background-color: #f4f6fb;
            }
          }
        }
      }
      .search-input {
        flex: 1;
        input {
          width:100%;
          height:50px;
          font-size: 14px !important;
          font-weight: 400;
          border-radius: 0.4rem 0 0 0.4rem;
        }
      }
      .location-input {
        display: none;
        input {
          height:50px;
          font-size: 14px !important;
    font-weight: 400;
          border-radius: 0 0.4rem 0.4rem 0;
        }
      }
      button {
        cursor: pointer;
        font-weight: 400;
        z-index: 1;
        /* margin-right: 2.4rem; */
        padding: 0.6rem 2rem;
        border: 0;
        background-color: ${(props) => props.theme.colors.flickrBlue};
        color: white;
        font-size: 14px;
        color: white;
        border-radius: 4px;
        box-shadow: 0px 3px 6px rgba(54, 98, 216, 0.3);
        display: flex;
        align-items: center;
        span {
          display: none;
        }
        svg {
          fill: white;
        }
      }
    }
    .recommendations {
      display: flex;
      overflow-x: auto;
      overflow-y: hidden;
      margin-top: 1.6rem;
      padding-left: 1.6rem;
      &.hidden {
        visibility: hidden;
      }
      .recommendation {
        background: #f7f7f7;
        border-radius: 3rem;
        flex: 0 0 auto;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 21.33px;
        a {
          width: 100%;
          display: inline-block;
          align-items: center;
          padding: 5px 20px;
          font-weight: 400;
          font-size: 13px;
          color: #6a6a6b;
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .header-image {
    display: none;
  }
  @media (min-width: 768px) {
    .header-container {
      height: 40vh;
    }
    .header-form {
      h1 {
        padding: 0 3.2rem;
      }
      form {
        padding: 0 3.2rem;
        .location-input {
          display: block;
        }
        button {
          margin-right: 0;

          span {
            display: block;
          }
          svg {
            display: none;
          }
        }
      }
      .recommendations {
        padding: 0 3.2rem;
        .recommendation {
          margin-right: 1.2rem;
          flex: 0 0 auto;
        }
      }
    }
  }
  @media (min-width: 1024px) {
    .header-container {
      height: 50vh;
      padding: 0;
      max-width: 946px;
      margin: auto;
    }
    .header-form {
      h1 {
        padding: 0;
      }
      form {
        padding: 0;
      }
    }
  }
  @media (min-width: 1280px) {
  
    
    .header-container {
      height: 438px;
      background: url(assets/images/doodle.png), #F9F9FA;
      margin-bottom: 78px;
      margin-top: 50px;
    
      border-radius: 10px 0px 0px 10px;
    }

    .header-form {
      width: 77%;
      margin-left: 53px;
      position: relative;
      z-index: 1;
      form .search-input input {
        width: 100%;}
      form .location-input {
        width: 8%
      }
   form   button{
       margin-left:8.8rem;
      }
      h1 {
        font-size: 36px;
        font-weight: 600;
        line-height: 41px;
        width: 100%;
      }
      .recommendations {
        padding: 0;
        overflow: hidden;
        .recommendation {
          background: #f0f0f0;
          font-size: 11px;
          font-weight: 500;
          margin-bottom: 1.6rem;
          flex: 0 0 31%;
    
          width: 138.07px;
          height: 29.86px;
         
        }
      }
    }
    .header-image {
   
      
      position: relative;
     
  
      display: flex;
      align-self: flex-end;
      justify-content: flex-end;
      width :380px;
      height : 438px;
      
      img {
        max-height: 100%;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        
        object-fit: none;
        position: absolute;
      
      }
    }
  }

`;

export const CategoriesCss = styled.div`
  padding: 2rem 0;
  background-color: white;
  .categories-tabs {
    overflow-x: auto;
    button {
      flex: 0 0 auto;
      outline: none;
      background: transparent;
      border: 0;
      display: flex;
      align-items: center;
      margin-right: 3.2rem;
      font-size: 1.8rem;
      font-weight: 500;
      padding: 0;
      padding-bottom: 0.8rem;
      color: #565459;
      cursor: pointer;
      border-bottom: 2px solid transparent;
      svg {
        width: 2rem;
        fill: #565459;
      }
      &:hover {
        color: ${(props) => props.theme.colors.flickrBlue};
        svg {
          fill: ${(props) => props.theme.colors.flickrBlue};
        }
        border-bottom: 2px solid ${(props) => props.theme.colors.flickrBlue};
      }
      &.active-tab {
        color: ${(props) => props.theme.colors.flickrBlue};
        svg {
          fill: ${(props) => props.theme.colors.flickrBlue};
        }
        border-bottom: 2px solid ${(props) => props.theme.colors.flickrBlue};
      }
      svg {
        margin-right: 1.2rem;
      }
    }
    a.more {
      display: none;
    }
  }
  .category-data {
    margin-top: 2.4rem;
    .header {
      height: 200px;

      background-image: url(${(props) => props.categoryImage});
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 4px;
      position: relative;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      .top {
        display: flex;
        align-items: center;
        width: 80%;
        margin-bottom: 1.4rem;
        z-index: 20;
      }
      .go-to-category {
        display: none;
        z-index: 20;

        margin-left: 4rem;
        align-items: center;
        position: relative;
        margin-bottom: 2rem;
        h3 {
          margin: 0;
          color: white;
          font-weight: 500;
          font-size: 1.5rem;
        }
        span {
          margin-left: 1.6rem;
          background-color: rgb(255 255 255 / 32%);
          color: white;
          padding: 0.4rem 1rem;
          border-radius: 4px;
          font-size: 1.4rem;
        }
      }
      img {
        width: 3.2rem;
        margin-right: 1.2rem;
        margin-left: 2rem;
        filter: invert(5);
        position: relative;
      }
      svg {
        display: none;
        margin-left: 4rem;
        font-size: 3.2rem;
        fill: white;
        position: relative;
        margin-right: 1.2rem;
      }
      h2 {
        font-size: 1.5rem;
        position: relative;
        font-weight: 600;
        color: white;
      }
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 0.4rem;
        z-index: 10;
        background: linear-gradient(
          180deg,
          rgba(49, 56, 78, 0) 23.96%,
          rgba(58, 67, 94, 0.8) 100%
        );
      }
    }
  }
  .service {
    &:nth-child(1) {
      margin-right: 0.6rem;
    }
    &:nth-child(2) {
      margin-left: 0.6rem;
      margin-top: 2rem;
    }
    &:nth-child(3) {
      display: none;
    }
    width: 49%;
    margin-top: 2rem;
    height: 10rem;
    position: relative;
    background-color: #bbbdbf;
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(
        180deg,
        rgba(49, 56, 78, 0) 50.06%,
        rgba(58, 67, 94, 0.6) 96.61%
      );
      border-radius: 0.4rem;
      z-index: 10;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0.4rem;
    }
    .service-details {
      position: absolute;
      bottom: 0.8rem;
      left: 0.8rem;
      color: white;
      z-index: 10;

      h3 {
        font-size: 1.5rem;
        line-height: 1.5rem;
        font-weight: 600;
        margin: 0;
        color: white;
        word-break: break-word;
      }
      p {
        display: none;
        font-size: 1.2rem;
      }
    }
  }
  .all-categories {
    display: flex;
    align-items: center;
    margin-top: 2.4rem;
    padding: 1rem 3.2rem;
    font-size: 1.5rem;
    background: var(--color-flickr-blue-shade);
    border-radius: 4px;
    color: ${(props) => props.theme.colors.flickrBlue};
    svg {
      margin-right: 1rem;
      font-weight: 600;
    }
  }

  @media (min-width: 768px) {
    padding: 4rem 0;
    .categories-tabs a.more {
      display: inline-block;
      background-color: ${(props) => props.theme.colors.flickrBlueShade};
      color: ${(props) => props.theme.colors.flickrBlue};
      padding: 1.2rem 2.8rem;
      font-size: 1.4rem;
      font-weight: 600;
      border-radius: 4px;
      svg {
        margin-left: 1.2rem;
        fill: ${(props) => props.theme.colors.flickrBlue};
      }
      &:hover {
        background-color: ${(props) => props.theme.colors.flickrBlue};
        color: white;
        svg {
          fill: white;
        }
      }
    }
    .service {
      width: 33%;
      height: 16rem;

      &:nth-child(2) {
        margin: 2rem 2rem 0;
      }
      &:nth-child(1) {
        margin-right: 0;
      }
      &:nth-child(3) {
        display: block;
      }
      .service-details {
        bottom: 1.6rem;
        left: 1.6rem;
      }
    }
  }
  .all-categories {
    display: none;
  }

  @media (min-width: 1024px) {
  
    .category-data .header img {
      margin-left: 4rem;
    }
    .service {
   
      .service-details {
        h3 {
          font-size: 1.8rem;
          line-height: 2rem;
        }
        p {
          display: block;
        }
      }
    }
    .category-data {
      .header {
        height: 264px;
        svg {
          display: block;
        }
        h2 {
          font-size: 2.4rem;
          margin: 0;
        }
        .go-to-category {
          display: flex;
        }
      }
    }
  }
  @media (min-width: 1280px) {
    padding: 7rem 0;
    padding-top:9rem;
    margin: auto;
    .service {

    }
  }
  @media (min-width: 1600px) {
    .service {

      .service-details {
        bottom: 2rem;
        left: 2.8rem;
        width: 80%;
      }
    }
  }
`;


export const PopularCss = styled.div`
 
  overflow: auto;
  padding: 4rem 2rem;
  .slider {
    width: calc(240px * 3);
    display: flex;
  }
  @media (min-width: 1024px) {
    padding: 6.4rem 0;
    overflow: hidden;
    .slider {
      animation: scroll 140s linear infinite;
      width: calc(400px * 10);
      &:hover {
        animation-play-state: paused;
      }
    }
  }
`;


export const BeAProCss = styled.div`
  padding: 6.4rem 0;
  background: #ffffff;


  .illustration {
    svg {
      width: 100%;
    }
  }
  .typography {
    margin-top: 3.4rem;
    h2 {
      font-size: 3.4rem;
      font-weight: 700;
      line-height: 4.8rem;
      color: ${(props) => props.theme.colors.jet};
    }
    p {
      font-size: 1.5rem;
      line-height: 1.8rem;
      color: #565459;
    }
    a {
      background: ${(props) => props.theme.colors.frenchViolet};
      color: white;
      font-size: 1.5rem;
      font-weight: 600;
      padding: 1.2rem 2.8rem;
      margin-top: 1.4rem;
      border-radius: 4px;
      display: inline-block;

      margin-bottom: 2.6rem;
    }
  }
  @media (min-width: 1024px) {
    .illustration {
      width: 50%;
    }
    .typography {
      width: 50%;
      h2 {
        font-size: 4rem;
        width: 80%;
      }
      p {
        font-size: 2.2rem;
        width: 80%;
        line-height: 2.8rem;
      }
    }
    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  @media (min-width: 1280px) {
    padding: 1.6rem 0;
    .typography {
      width: 40%;
    }
    .content {
      width: 100rem;
      margin: auto;
    }
  }
`;
