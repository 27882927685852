import { TOGGLE_SEARCHBAR } from 'actions/types';
import { Button, Input} from 'antd';
import { Cancel, Search, Search_service } from 'helpers/dictionary';
import useGetSearchFunction from 'hooks/useGetSearchResults';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function useOutsideAlerter(ref, setShowResults) {
    useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setShowResults(false);
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
}



const SearchBox =({func})=>{
    const [query, setQuery] = useState('');
    const dispatch = useDispatch();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_showResults, setShowResults] = useState(false);
    const lang = useSelector((state) => state.settings.lang);
    const { searchResults } = useGetSearchFunction(query, lang,false,true);
    const router = useRouter();
    const searchResultsRef = useRef(null);
    useOutsideAlerter(searchResultsRef, setShowResults);


    useEffect(()=>{
        if((typeof window !== 'undefined' && window.innerWidth>768)){

            router.push('/');
        }
    },[]);
    const sendSearchQuery = () => {

        router.replace(`/service/${searchResults[0].id}`);
        dispatch({
            type: TOGGLE_SEARCHBAR,
            payload: false
        });
    };
    const displayResults = () => {
        setShowResults(true);
    };
    return(
        <div>
            <div className="header-search" style={{display:'flex'}}>
                <div className="col">

                    <Button onClick={()=>{func(false);  dispatch({
                        type: TOGGLE_SEARCHBAR,
                        payload: false
                    });  }}style={{background:'var(--color-flickr-blue)',color:'white',fontSize:'12px',fontWeight:'600',borderRadius:'4px',height:'38px'}}>{Cancel[lang]}</Button>

                </div>



                <svg height={24} viewBox="0 0 219.42 83.75"><path d="M27.4 68.35c-12.51.1-20.06-5.69-20.06-20.27V26.06H0V12.1h7.34V1.45L23.57 0v12.1h11.68v13.96H23.57v22.02c0 4.96 3.62 6.51 7.55 6.51 1.55 0 3.21-.21 4.66-.52l.52 13.44c-3.21.53-6.21.84-8.9.84zM38.88 39.5c0-39.91 59.66-39.81 59.66 0s-59.66 39.6-59.66 0zm43.32 0c0-19.03-26.99-18.92-26.99 0 .01 18.71 26.99 18.82 26.99 0zm38.88 22.75v21.51h-16.13V11.48h16.13v5.58c3.83-4.76 9.3-6.31 14.68-6.31 17.79 0 26.99 14.27 26.89 29.67-.21 15.3-8.99 27.92-27.09 27.92-5.48.01-10.76-1.85-14.48-6.09zm0-22.44c0 7.45 5.69 13.55 12.72 13.55 8.27 0 12.41-6.62 12.51-13.34s-4.14-13.65-12.51-13.65c-7.03 0-12.72 5.99-12.72 13.44z" fill="var(--color-indigo)"></path><path d="M196.98 17.68l4.13-6.31h18.1v1.65l-16.54 25.95 16.75 27.3v1.14h-18.09l-4.03-6.72-5.28-10.34-5.27 10.34-3.93 6.72h-18.1v-1.14l16.75-27.3-16.55-25.95v-1.65h18.1l4.03 6.31 4.96 9.62 4.97-9.62z" fill="#724c9f"></path></svg>

                <Button onClick={sendSearchQuery} style={{background:'var(--color-flickr-blue)',color:'white',fontSize:'12px',fontWeight:'600',borderRadius:'4px',height:'38px'}}>{Search[lang]}</Button>
            </div>

            <div className="content" style={{background: '#EDEDED',height:'100vh'}}>
                <div className="input" style={{padding: '14px 20px'}}>
                    <Input style={{height:'50px',border:'0px solid #d9d9d9'}} value={query}
                        onChange={(e)=>{setQuery(e.target.value);}}
                        onClick={displayResults}   placeholder={Search_service[lang]}/>
                </div>

                {/* <div className="input" style={{padding: '0px 20px',marginTop:'-15px',color:'#111111',fontWeight:'500'}}>
                    <Input style={{height:'50px',color: 'var(--color-french-violet)', fontSize: '15px',fontWeight: '600', background: '#F4F6FB',border:'0px solid #d9d9d9'}} readOnly    placeholder={Tashkent[lang]}
                        value={Tashkent[lang]} />
                </div> */}

                { (searchResults&&
                        <div style={{padding: '0px 20px',marginTop:'-26px'}}>
                            <ul  style={{display:'inline',listStyle:'none'}}className="search-results">
                                {searchResults
                                    ? searchResults.slice(0, 5).map((result, i) => {
                                        return (
                                            <li key ={i} className="list-search"style={{height:'50px'}}>
                                                <div style={{ background: 'white',marginLeft:'0px',padding:'12px 12px',fontSize:'16px', fontWeight:'500',borderBottom:'1px solid white'}}>
                                                    <Link href={'/service/' + result.id}>
                                                        <a>
                                                            {lang === 'ru'
                                                                ? result.titleRU
                                                                : lang === 'uz'
                                                                    ? result.titleUZ
                                                                    : result.titleEN}
                                                        </a>
                                                    </Link>
                                                </div>
                                            </li>

                                        );
                                    })
                                    : <> null </>}


                            </ul>
                        </div>
                )}

            </div>
        </div>

    );

};

export default SearchBox;