

import { useSelector } from 'react-redux';
import dynamic from 'next/dynamic';
import axios from '../utils/axios';
const Header =dynamic(()=>import('../components/homepage/Header'));
const Popular =dynamic(()=>import('../components/homepage/Popular'));
const Categories =dynamic(()=>import('../components/homepage/Categories'));
const BeAPro =dynamic(()=>import('../components/homepage/BeAPro'));
const SEO =dynamic(()=>import('../components/SEO'));
import { useRedirectUnauthorized } from '../hooks/useRedirectUnauthenticated';
import ServiceBenefit from 'components/homepage/ServiceBenefit';
import { useState } from 'react';
import SearchBox from 'components/homepage/SearchBox';


const Index = ({ main, popular, servicelistPopular,servicelistBenefits }) => {
    const settingsState = useSelector((state) => state.settings);
    const [search ,showSearch]= useState(false);
    useRedirectUnauthorized({ allowPros: false });
    // console.log(servicelist)
    return (
        <div>
            { search? <>
                <SEO title="Welcome to Topx/Search Services" />
                <SearchBox func={(e)=>showSearch(e)} />
            </>
                :
                <>
                    <SEO title="Welcome to Topx" />
                    <Header func={(e)=>showSearch(e)} popular={popular} lang={settingsState.lang} />
                    <Popular lang={settingsState.lang} popular={popular} main={main} data={servicelistPopular}/>
                    <Categories lang={settingsState.lang} main={main} />
                    <ServiceBenefit  lang={settingsState.lang} popular={popular} main={main}  data={servicelistBenefits} />
                    <BeAPro lang={settingsState.lang} />
                </>
            }
        </div>
    );
};

Index.getInitialProps = async () => {
    const main = await axios.get(
        `${process.env.BACKEND_URL}/api/v1/category/main`
    );

    const popular = await axios.get(
        `${process.env.BACKEND_URL}/api/v1/service/popular`
    );
    const servicelistPopular = await axios.get(
        `${process.env.BACKEND_URL}/api/v1/service/populars`
    );
    const servicelistBenefits = await axios.get(
        `${process.env.BACKEND_URL}/api/v1/service/benefits`
    );


    return {
        main: main.data,
        popular: popular.data,
        servicelistPopular: servicelistPopular.data,
        servicelistBenefits:servicelistBenefits.data
    };
};

export default Index;
