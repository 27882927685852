import React, { memo } from 'react';
import styled from 'styled-components';

import Link from 'next/link';
import dynamic from 'next/dynamic';
import { AvgCost } from 'helpers/dictionary';
const Image= dynamic(()=>import('components/Image'));

const CardCss = styled.div`
  padding-right: 27.5px;
  
  .content {
    width: 240px;
    position: relative;
    display: flex;
    justify-content: center;
    &:before {
      background: linear-gradient(
        180deg,
        rgba(49, 56, 78, 0) 50.06%,
        rgba(58, 67, 94, 0.6) 96.61%
      );
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .image {
    height: 140px;
    width: 100%;
    border-radius: 5px;
    margin: 0 auto;
    width: 100%;
    cursor: pointer;
    object-fit: cover;
  }
  h3 {
    font-size: 1.2rem;
    margin: 0;
    position: absolute;
    top: 75%;
    left: 0;
    z-index: 1;
    font-weight: 700;
    width: 100%;
    text-align: center;
    color: white;
  }
  @media (min-width: 1024px) {
    &:nth-child(even) {
      margin-top: 2rem;
    }
    &:nth-child(odd) {
      margin-top: 2rem;
    }
    transition: 0.2s transform ease-in-out;
    &:hover {
      transform: scale(1.1);
    }
    .content {
      width: 296.8px;
      height: 192.72px;
    }
    .image {
      height: 200px;
      img {
        border-radius: 0.4rem;
      }
    }
    h3 {
      font-size: 1.6rem;
      top: 85%;
    }
  }
  @media (min-width: 1600px) {
    padding-right: 35px;
    padding-left: 35px;

    .content {
      width: 400px;
      height: 260px;
    }
  }
`;

const PopularCard = ({ lang, service,dis }) => {
    return (
        <>
            <div className="header-container">
                <CardCss animate={{ opacity: 1 }}>
                    <Link href={'/service/' + service.id}>
                        <a>
                            <div className="content">
                                <div className="image">
                                    <picture>
                                        <source
                                            media="(max-width: 1279px)"
                                            srcSet="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8/5+hHgAHggJ/PchI7wAAAABJRU5ErkJggg=="
                                            sizes="100%"
                                        />
                                        <Image
                                            quality={65}
                                            src={process.env.ASSETS_URL + '/' + service.imageUrl}
                                            layout="fill"
                                            alt="service image"
                                            style={{borderRadius:'4px'}}
                                        />
                                    </picture>
                                </div>
                                {/* <h3>
                            {lang === 'ru'
                                ? service.titleRU
                                : lang === 'uz'
                                    ? service.titleUZ
                                    : service.titleEN}
                        </h3> */}
                            </div>
                        </a>
                    </Link>
                </CardCss>

                <h3 className="textTitle">
                    {lang === 'ru'
                        ? service.titleRU
                        : lang === 'uz'
                            ? service.titleUZ
                            : service.titleEN}
                    <br></br>

                    {!dis?service.AvgCost===''?null:<span className="textSmallT">
                        {AvgCost[lang]}: {service.AvgCost} </span>:null }
                </h3>


            </div>
        </>
    );
};

export default memo(PopularCard);
