import React, { useState, memo } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import LazyLoad from 'react-lazyload';
import Container from '../styles/Container';
import Flex from '../styles/Flex';
import {
    All_services,
    ServicesBe,
} from '../../helpers/dictionary';
import { CategoriesCss, PopularCss } from '../styles/homepage';
import PopularCard from './PopularCard';

const ServiceBenefit = ({ main, lang, data }) => {
    const [activeCategory/* , setActiveCategory */] = useState(0);

    // const handleCategoryTabsChange = (e) => {
    //     if (e.target.dataset.index) {
    //         setActiveCategory(e.target.dataset.index);
    //     }
    // };

    const categoryImage =
    main[activeCategory] && main[activeCategory].imageUrl
        ? process.env.ASSETS_URL + '/' + main[activeCategory].imageUrl
        : '';

    const mainServices = data&& data.length>0&& data
        .filter((s) => s.benefits===true);

    return (
        <>
            <LazyLoad   className="lazyS lazyW"style={{marginTop:'70px',background: '#F7F7F7',display:data&& data.length>0?'':'none'
            }}once>
                <CategoriesCss categoryImage={categoryImage} style={{background: '#F7F7F7',padding:'12rem 0'}}>
                    <Container className="categ">
                        <h3 style={{fontWeight: 'bold',
                            fontSize: '22px',
                            lineHeight: '28px',marginBottom:'-2rem'}}>{ServicesBe[lang]}</h3>
                        <div className="category-data">

                            <Flex justify="space-between" align-items="center">
                                {
                                    mainServices.length > 0
                                && mainServices.sort(() => Math.random() - Math.random()).slice(0, 3).map((service, i) => {
                                    const imageUrl = service.imageUrl
                                        ? process.env.ASSETS_URL + '/' + service.imageUrl
                                        : '/assets/images/topx-user.jpg';
                                    return (
                                        <Link key={i} href={'/service/' + service.id}>
                                            <a className="service">
                                                {service.imageUrl && (
                                                    <Image
                                                        layout="fill"
                                                        src={imageUrl}
                                                        alt={
                                                            lang === 'ru'
                                                                ? service.titleRU
                                                                : lang === 'uz'
                                                                    ? service.titleUZ
                                                                    : service.titleEN
                                                        }
                                                    />
                                                )}
                                                <div className="service-details details_mobile" style={{bottom:'-18.4rem',left:'0rem',position:'relative'}}>
                                                    <h3 style={{color:'black',fontSize:'15px',fontWeight:'600'}}>
                                                        {lang === 'ru'
                                                            ? service.titleRU
                                                            : lang === 'uz'
                                                                ? service.titleUZ
                                                                : service.titleEN}
                                                    </h3>
                                                </div>
                                            </a>
                                        </Link>
                                    );
                                })
                                }
                            </Flex>
                            <Flex justify="center">
                                <Link href="/categories">
                                    <a className="all-categories">
                                        <svg
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M1.33331 1.99999C1.33331 1.63181 1.63179 1.33333 1.99998 1.33333H6.66665C7.03484 1.33333 7.33331 1.63181 7.33331 1.99999V6.66666C7.33331 7.03485 7.03484 7.33333 6.66665 7.33333H1.99998C1.63179 7.33333 1.33331 7.03485 1.33331 6.66666V1.99999ZM2.66665 2.66666V6H5.99998V2.66666H2.66665Z"
                                                fill="var(--color-flickr-blue)"
                                            />
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M8.66669 1.99999C8.66669 1.63181 8.96516 1.33333 9.33335 1.33333H14C14.3682 1.33333 14.6667 1.63181 14.6667 1.99999V6.66666C14.6667 7.03485 14.3682 7.33333 14 7.33333H9.33335C8.96516 7.33333 8.66669 7.03485 8.66669 6.66666V1.99999ZM10 2.66666V6H13.3334V2.66666H10Z"
                                                fill="var(--color-flickr-blue)"
                                            />
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M8.66669 9.33334C8.66669 8.96515 8.96516 8.66667 9.33335 8.66667H14C14.3682 8.66667 14.6667 8.96515 14.6667 9.33334V14C14.6667 14.3682 14.3682 14.6667 14 14.6667H9.33335C8.96516 14.6667 8.66669 14.3682 8.66669 14V9.33334ZM10 10V13.3333H13.3334V10H10Z"
                                                fill="var(--color-flickr-blue)"
                                            />
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M1.33331 9.33334C1.33331 8.96515 1.63179 8.66667 1.99998 8.66667H6.66665C7.03484 8.66667 7.33331 8.96515 7.33331 9.33334V14C7.33331 14.3682 7.03484 14.6667 6.66665 14.6667H1.99998C1.63179 14.6667 1.33331 14.3682 1.33331 14V9.33334ZM2.66665 10V13.3333H5.99998V10H2.66665Z"
                                                fill="var(--color-flickr-blue)"
                                            />
                                        </svg>

                                        <span>{All_services[lang]}</span>
                                    </a>
                                </Link>
                            </Flex>
                        </div>
                    </Container>
                </CategoriesCss>
            </LazyLoad>
            <h3  className="PopS" style={{fontWeight: 'bold',
                fontSize: '22px',
                lineHeight: '28px',padding:'0 2rem', marginBottom:'-2rem'}}>{ServicesBe[lang]}</h3>

            <PopularCss className="PopS">

                <div className="slider">
                    {mainServices.length > 0
                                    &&mainServices.sort(() => Math.random() - Math.random()).slice(0,3).map((service, i) => (
                                        <PopularCard dis={true} lang={lang} key={i} service={service} />
                                    ))}
                </div>
            </PopularCss>
        </>
    );
};

export default memo(ServiceBenefit);
